<script>
import { Line } from 'vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'


export default {
  extends: Line,
  props: ['height','data','title'],
    computed: {
        chartData: function() {
            return this.data;
        }
    },
  mounted () {
      this.renderLineChart()
  },
    watch: {
        data: function() {
            this.$data._chart.destroy()
            this.renderLineChart();
        }
    },
    methods: {

        renderLineChart: function () {
            const brandSuccess = getStyle('--success') || '#4dbd74'
            const brandInfo = getStyle('--info') || '#20a8d8'

            this.renderChart({
                labels: this.data['labels'],

                datasets: [
                    {
                        label: 'This Month',
                        backgroundColor: hexToRgba(brandInfo, 10),
                        borderColor: brandInfo,
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,
                        data: this.data['current']
                    },
                    {
                        label: 'Previous Month',
                        backgroundColor: 'transparent',
                        borderColor: brandSuccess,
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,
                        data: this.data['past']
                    }
                ]
            }, {
                tooltips: {
                    enabled: false,
                    custom: CustomTooltips,
                    intersect: true,
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        labelColor: function (tooltipItem, chart) {
                            return {backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor}
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: true
                },
                title: {
                    display: true,
                    text: this.title
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            // maxTicksLimit: 5,
                            // stepSize: Math.ceil(250 / 5),
                            // max: 250
                        },
                        gridLines: {
                            display: true
                        }
                    }]
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3
                    }
                }
            })
        }
    }
}
</script>
